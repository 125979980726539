
import Vue from 'vue';
import Component from 'vue-class-component';
import { closeButton } from '@/utils/image-paths';

@Component
export default class Modal extends Vue {
  show = false;
  closeButtonIcon = closeButton;

  closeModal(): void {
    this.show = false;
    document.querySelector('body')?.classList.remove('overflow-hidden');
  }

  openModal(): void {
    this.show = true;
    document.querySelector('body')?.classList.add('overflow-hidden');
  }
}
