
import dayjs from 'dayjs';
import { Component, Vue, Prop, Ref } from 'vue-property-decorator';

// Components
import Info from '@/components/offers/icons/Info.vue';
import Location from '@/components/offers/icons/Location.vue';
import Modal from '../modal/Modal.vue';
import OfferDetails from './OfferDetails.vue';

// Utilities
import { formatToUrl } from '@/utils/format-to-url';
import { getBgColor } from '@/utils/helperFunctions';
import {
  boostGraphic,
  boostOfferGraphic,
  cashbackGraphic,
  cashForwardGraphic,
  cashOfferGraphic,
  locationIcon,
  offerOverlay
} from '@/utils/image-paths';
import isMobile from '@/utils/mobile-detect';

// Types
import { OfferDetail, IStore, IBooster } from '@/types/offer.types';
import { IRedemption } from '@/types/redeemed-offers.types';

// Config Variables
import { ActiveTenant } from '../../../active-tenant';
import { tenants } from '../../../tenant-config';

@Component({
  components: { Modal, OfferDetails, Location, Info }
})
export default class FoodBankOfferCard extends Vue {
  @Prop() offer!: OfferDetail;
  @Prop() redemption!: IRedemption;

  @Prop() title!: string;
  @Prop() isRedeemedOffer!: boolean;
  @Prop() inprogress!: boolean;

  @Prop() comboOffer!: any;
  @Prop() offerCount!: number;

  @Ref('mainModel') modal!: any;

  location: string = locationIcon;
  boostOfferGraphicImg: string = boostOfferGraphic;
  cashOfferGraphicImg: string = cashOfferGraphic;
  offerOverlayImg: string = offerOverlay;
  activeTenant = tenants[ActiveTenant];
  buttonColor = this.activeTenant.offerCard.buttonColor;
  headerPrimaryBackground = this.activeTenant.offerCardHeaderPrimaryBackground;
  headerSecondaryBackground = this.activeTenant.offerCardHeaderSecondaryBackground;
  yellowArrow = this.activeTenant.yellowArrow;
  accentTextColor = this.activeTenant.text.offerCardAccent;
  iconAddress = this.activeTenant.iconAddress;
  iconPhone = this.activeTenant.iconPhone;
  iconWeb = this.activeTenant.iconWeb;

  cashForwardAmount = 0;
  offerBackground = '';
  matchingValuePercent = 0;
  currentBoosterPos = 3;
  sliderId = '';
  dayjs = dayjs;

  get brandWebsite(): string {
    if (this.offer.brandWebsite) return new URL(this.offer.brandWebsite).host;
    return '';
  }

  formatToUrl = formatToUrl;

  get isOnlineOnly(): boolean {
    if (this.offer.reach) return this.offer.reach === 'online_only';
    return false;
  }

  get store(): IStore {
    if (this.offer.storeDetails && this.offer.storeDetails?.length > 0) return this.offer?.storeDetails[0];
    return {};
  }

  get status(): string {
    // Active , Expired , Scheduled,  Archived
    if (this.offer.offerState === 'scheduled') {
      return 'Coming Soon';
    } else if (this.offer.offerState === 'active') {
      if (this.offer.reach === 'online_only') {
        return this.offer.redeemLimitPerUser
          ? `Online | Limit of ${this.offer.redeemLimitPerUser}`
          : 'Online';
      } else if (this.offer.redeemedCount! > 0) {
        return this.offer.redeemLimitPerUser
          ? `In Progress ${this.offer.redeemedCount} of ${this.offer.redeemLimitPerUser}`
          : 'In Progress';
      } else {
        return this.offer.redeemLimitPerUser ? `Limit of ${this.offer.redeemLimitPerUser}` : '';
      }
    } else if (this.offer.offerState === 'redeemed') {
      return this.offer.redeemLimitPerUser
        ? `Redeemed | Limit of ${this.offer.redeemLimitPerUser}`
        : 'Redeemed';
    } else if (this.offer.offerState === 'expired') {
      return this.offer.redeemLimitPerUser
        ? `Expired | Limit of ${this.offer.redeemLimitPerUser}`
        : 'Expired';
    }

    return this.offer.offerState;
  }

  get redemptionStatus() {
    // earned , in_progress , expired
    if (this.redemption?.rewardStatus === 'earned') return 'Redeemed';
    if (this.redemption?.rewardStatus === 'in_progress') {
      if (this.offer.redemptionTrigger === 'purchase_frequency')
        return `In progress ${this.redemption?.transactions.length}/${this.offer.purchaseFrequency}`;
      else if (this.offer.redemptionTrigger === 'cumulative_purchase_amount')
        return `In progress $${this.redemption?.purchaseValue.toFixed(2)}/$${this.offer.purchaseAmount}`;
    }
    return this.redemption?.rewardStatus;
  }

  get rewardValue() {
    //if (this.offer.reward != null) {
    const value = this.offer.rewardValue;
    const type = this.offer.rewardType;
    return type === 'fixed' ? `$${value}` : `${value}%`;
    //}
  }

  get getBoosters(): IBooster[] {
    return this.offer.boosters
      .map((value) => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value);
  }

  get getDonationValue(): string {
    var boostsValues = 0.0;
    this.offer.boosters.map((item) => {
      boostsValues += item.matchings[0].matchingValue;
    });

    const value = `${this.offer?.rewardValue}`.replace('$', '');
    const grandTotal: number = boostsValues + parseFloat(value ?? 0);

    return grandTotal.toFixed(2);
  }

  get getPercentValue(): string {
    var boostsValues = 0.0;
    this.offer.boosters.map((item) => {
      boostsValues += item.matchings[0].matchingValue;
    });

    const rewardValue = `${this.offer?.rewardValue}`.replace('$', '');
    const percentValue: number =
      (boostsValues / 100) * parseFloat(rewardValue) + parseFloat(rewardValue) || 0;

    const grandTotal = Number.isInteger(percentValue) ? percentValue.toFixed(0) : percentValue.toFixed(2);

    return grandTotal;
  }

  formatPhoneNumber(phone: string): string {
    if (phone) {
      // Remove non-digit characters from the phone number
      const cleanedPhone = phone.replace(/\D/g, '');

      // Check if the phone number starts with "+1"
      const hasPlusOne = cleanedPhone.length > 10 && cleanedPhone.startsWith('1');

      // Extract the country code and the remaining digits
      const countryCode = hasPlusOne ? '+1 ' : '';
      const remainingDigits = hasPlusOne ? cleanedPhone.substring(1) : cleanedPhone;

      // Format the phone number
      return `${countryCode}${remainingDigits.substring(0, 3)}-${remainingDigits.substring(
        3,
        6
      )}-${remainingDigits.substring(6)}`;
    } else return phone;
  }

  created(): void {
    //redeemed expired
    // this.offer.offerState = 'redeemed';

    this.offerBackground = getBgColor(this.title);
    if (this.offer.boosters) {
      this.matchingValuePercent = this.offer.boosters
        .map((b) => b.matchings[0].matchingValue)
        .reduce((previous, current): number => previous + current, 0);
    }

    // Generate unique ID for slider
    this.sliderId = `slider-${Math.floor(100000 + Math.random() * 900000)}`;
  }

  getOfferGraphic(): string {
    let graphic = '';

    if (this.title === 'Cash Back') {
      graphic = cashbackGraphic;
    } else if (this.title === 'Cash Forward') {
      graphic = cashForwardGraphic;
    } else if (this.title === 'Boost') {
      graphic = boostGraphic;
    }

    return graphic;
  }

  navigateToDetailsPage(): void {
    if (isMobile() === true) {
      this.$router.replace({
        name: 'OffersDetails',
        query: { id: this.offer.id }
      });
    } else {
      this.modal.openModal();
    }
  }

  closeTheModel(): void {
    this.modal.closeModal();
  }

  // Navigate between boosting partners
  moveToNextBooster(event: any): void {
    let slider = document.querySelector(`#${event?.target?.dataset?.id}`) as HTMLElement;

    if (slider.offsetWidth / 92 > this.currentBoosterPos) {
      slider.style.left = `${slider.offsetLeft - 92}px`;
      this.currentBoosterPos++;
    }
  }

  moveToPreviousBooster(event: any): void {
    let slider = document.querySelector(`#${event?.target?.dataset?.id}`) as HTMLElement;

    if (this.currentBoosterPos > 3) {
      slider.style.left = `${slider.offsetLeft + 92}px`;
      this.currentBoosterPos--;
    }
  }

  scrollToBoosterCards(event: any): void {
    const id = `booster-${event}`;
    const yOffset = -88 - 10;
    const element = document.getElementById(id);
    const y = (element?.getBoundingClientRect().top ?? 0) + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });
  }
}
