// Types
import { IBrandResponse } from './brand.types';

export interface IBooster extends ICorporate {
  matchings: IMatching[];
}

export interface IBoosters {
  title?: string;
  id?: string;
  coverImg: string;
  coverTitle?: string;
  description: string;
  logoUrl: string;
  name: string;
  websiteUrl?: string;
  matchings: IMatching[];
}

export interface IBrand {
  createdAt: string;
  deleted: boolean;
  id: string;
  lastSyncAt: string;
  logoUrl: string;
  name: string;
  updatedAt: string;
  websiteUrl: string;
}

export interface ICorporate {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  logoUrl: string | null;
  coverImg: string | null;
  name: string;
  description: string | null;
  websiteUrl: string | null;
  address1: string | null;
  address2: string | null;
  city: string | null;
  countryCode: string | null;
  phone: string | null;
  postCode: string | null;
  state: string | null;
  title: string | null;
}

export interface IExploreOfferCard {
  id: string;
  title?: string;
  src?: string;
  offer: string;
}

export interface IMatching {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  created: Date;
  isActive: boolean;
  matchingType: string;
  matchingValueType: string;
  matchingPeriod: string;
  matchingValue: number;
  maximumAmount: number;
  minimumAmount: number;
  roundingType: string | null;
  endDate: Date;
  startDate: Date;
  corporateName?: string;
  corporateId?: string;
  brand?: IBrandResponse;
}

export interface IOfferFilter {
  brandId?: string;
  offerState?: string;
  rangeStartDate?: string;
  rangeEndDate?: string;
  extOfferId?: string;
  city?: string;
  pageSize?: number;
  pageNumber?: number;
  order?: string;
  oliveMemberId?: string;
}

export enum OfferType {
  CARD_LINKED_AMOUNT = 'card_linked_amount',
  CARD_LINKED_PERCENT = 'card_linked_percent',
  CLICK_AMOUNT = 'click_amount',
  CLICK_PERCENT = 'click_percent'
}

export interface IOfferResponse {
  id: string;
  coverImg: string | null;
  daysAvailability: string;
  description: string;
  endDate: Date;
  offerState: string;
  offerType: OfferType | null;
  purchaseAmount: number;
  purchaseFrequency: number | null;
  reach: string;
  redeemLimitPerUser: number | null;
  redemptionTrigger: string;
  redemptionType: string;
  rewardType: string;
  rewardValue: number;
  startDate: Date;
  tile: string;
  title: string;
  brand?: IBrandResponse;
}

export interface IReward {
  id: string;
  type: string;
  value: number;
  maxValue?: number;
}

export class IStore {
  id?: string;
  name?: string;
  phone?: string;
  address1?: string;
  city?: string;
  state?: string;
  postCode?: string;
  geoLocation?: { latitude: number; longitude: number };
}

export class OfferDetail {
  id!: string;
  coverImg!: string;

  destinationId?: string;
  destinationName?: string;
  destinationImg?: string;

  created?: string;
  updated?: string;

  brandDba?: string;
  brandLogo?: string;
  brandWebsite?: string;
  brandId?: string;
  brandStub?: string;

  campaignId?: string;

  storeDetails?: IStore[];
  stores?: string[];

  reward?: IReward;
  rewardValue?: string;
  rewardType?: string;

  offerState!: string;
  description!: string;
  reach!: string;
  title!: string;
  qualifier?: string;
  tile!: string;
  currency!: string;
  daysAvailability!: number[];
  redemptionType!: string;
  redemptionInstructionUrl?: string;
  redemptionTrigger!: string;
  purchaseAmount!: number;
  purchaseFrequency?: number;
  budget!: number;
  totalRedeemLimit?: number;
  redeemLimitPerUser?: number;
  startDate!: Date;
  endDate!: Date;
  extOfferId?: string;
  supplierOfferKey?: string;
  redeemedCount?: number;

  boosters!: IBooster[];
  brand!: IBrand;

  matchings!: IMatching[];
  offerType!: OfferType | null;

  inprogress?: boolean;
}
