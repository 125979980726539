
import { Component, Vue, Prop } from 'vue-property-decorator';
import isMobile from '@/utils/mobile-detect';
import OfferDetailsHeader from './OfferHeader.vue';

// Import icons
import { closeButton } from '@/utils/image-paths';
import { IStore, OfferDetail } from '@/types/offer.types';

@Component({
  components: { OfferDetailsHeader }
})
export default class OfferDetails extends Vue {
  @Prop({ default: new OfferDetail() }) offer!: OfferDetail;
  @Prop() title!: string;
  @Prop() address!: IStore;
  @Prop() status!: string;

  closeButtonIcon = closeButton;
  isMobileView = isMobile();

  closeModal(): void {
    if (this.isMobileView === true) {
      this.$router.push(`/offers`);
    } else {
      this.$emit('closeTheModel');
      window.scrollTo(0, 350);
    }
  }

  goBackToOffersPage(): void {
    this.$router.replace({
      name: 'offers',
      query: { goto: this.offer.brand.name }
    });
  }
}
