
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class OfferDetailsHeader extends Vue {
  @Prop() merchant!: string;
  @Prop() logo!: string;
  @Prop() comboOffer!: any;

  getMerchantName(): string {
    let merchantName = '';

    if (this.comboOffer) {
      merchantName = `${this.merchant} and ${this.comboOffer.merchantName}`;
    } else {
      merchantName = this.merchant;
    }

    return merchantName;
  }
}
